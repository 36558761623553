<template>
  <div class="employContainer">
    <div style="overflow-y: scroll;height:100%;width:100%" ref="GoTopContent">
      <Title></Title>
        <div class="banner">
            <h5 style="font-size:46px;font-weight:600">招聘管理系统</h5>
            <button class="buttonShow" @click="$router.push('/freeTrial')" style="margin-top:40px">立即预约演示</button>
        </div>

        <div class="mainFunction">
            <IntroduceCare
              :fontDesc="mainFunction"
              class="mainFunctionCard"
            ></IntroduceCare>
        </div>

        <div class="systemArchitecture">
            <h4 class="descH4">系统功能图</h4>
            <span class="line"></span>
            <img src="@/assets/product/employment/frame.png" alt="" style="width:968px;margin-top:30px">
        </div>

        <div class="systemValue">
          <h5 class="descH4">系统价值优势</h5>
          <span class="line"></span>
          <div class="systemValueContent">
            <div v-for="(item,index) in valueList" :key="index" class="valueItem">
              <div class="valueItemMask"></div>
              <div class="valueTitle">{{item.title}}</div>
              <div class="valueLine"></div>
              <div class="valueText">{{item.content}}</div>
            </div>
          </div>
        </div>

      <Foot></Foot>
    </div>
    <BackTop class="backTop"></BackTop>
  </div>
</template>

<script>
export default {
  name: 'ProductEmployment',
  data () {
    return {
      mainFunction: [
        {
          title: '招聘管理系统',
          fontLeft: false,
          p: [
            {
              desc: '对招聘工作实现网络化管理，对招聘计划、职位发布、简历投递、简历筛选、招聘评审、笔试、面试等实现全过程的流程化管理，可以实现移动招聘，为管理者及应聘者提供便捷的服务。'
            }
          ],
          src: require('@/assets/product/employment/zhaoping.png')
        }
      ],
      valueList: [
        { title: '个性化招聘体验', content: '与用户风格相匹配的招聘网站设计，宣传雇主文化，提升候选人应聘体验。' },
        { title: '定制化招聘服务', content: '不同类别人员定制不同的招聘方案，自定义招聘流程、简历表、消息模板等，满足各种岗位招聘需求。' },
        { title: '智能化招聘协作', content: '从招聘计划发布、简历投递、简历筛选、笔试面试、专家评审、考察体检、发送offer到报到入职的全流程业务办理。' },
        { title: '精准化人才选拔', content: '基于人才资源池进行人才价值评估和定位，精准识别人才，实现岗位信息与人才自动匹配。' }
      ]
    }
  },
  methods: {
    getHeight () {
      if (window.innerWidth <= 800) {
        this.$router.push('/moProduct/moEmployment')
      }
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  },
  created () {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  }
}
</script>

<style scoped lang='scss'>
*{
    margin: 0;
    padding: 0;
}
img {
    object-fit: cover;
}
button:hover{
    cursor: pointer;
}
.line{
    display: inline-block;
    width: 40px;
height: 6px;
background: #D70C0C;
border-radius: 3px;
}
.buttonShow:hover{
box-shadow: 0px 8px 32px -6px rgba(131, 7, 7, 0.4);
}
.descH4{
font-size: 36px;
font-weight: 500;
color: #222222;
padding: 60px 0 20px;
}
.employContainer{
  position: relative;
  top: 0;
  left: 0;
  min-width: 1200px;
  height: 100vh;
  overflow: hidden;
}
.banner{
    width: 100%;
    height: 460px;
    background: url('~@/assets/product/employment/banner.png') center no-repeat;
    background-size: cover;

    text-align: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.banner button{
width: 200px;
height: 60px;
background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
border-radius: 34px;
border: 0;
color: #fff;
font-size: 16px;
}
.designConcept{
    width: 100%;
height: 336px;
background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
display: flex;
flex-direction: column;
align-items: center;
}
.systemArchitecture{
    width: 100%;
height: 610px;
background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
display: flex;
flex-direction: column;
align-items: center;
}
.mainFunction{
width: 100%;
height: 450px;
background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .mainFunctionCard .CareContent{
    padding: 60px 0 40px;
}
::v-deep .mainFunctionCard .CareContent .right img{
width: 560px;
}

.systemValue{
      width: 100%;
height: 630px;
background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
display: flex;
flex-direction: column;
align-items: center;
}
.systemValueContent{
  width: 1200px;
  padding-top: 40px;
  display: flex;
  flex-wrap: wrap;
}
.valueItem{
  position: relative;
  top: 0;
  left: 0;
  width: 580px;
  margin: 0px 20px 40px;
  height: 180px;
  border-radius: 10px;
  padding: 30px 40px;
  background-color: #fff;
  box-sizing: border-box;
  cursor: pointer;
  .valueTitle{
    font-weight: 500;
    font-size: 24px;
    color: #222222;
  }
  .valueLine{
    width: 30px;
    height: 4px;
    margin: 14px 0;
    background: #D70C0C;
  }
  .valueText{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #444;
  }
}
.valueItemMask{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.valueItem:nth-child(2n){
  margin-right: 0px;
}
.valueItem:nth-child(2n-1){
  margin-left: 0px;
}
.valueItem:hover{
  box-shadow: 0px 8px 20px 0px rgba(173,0,0,0.3);
    background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  .valueItemMask{
    display: block;
    background: url('~@/assets/hoverBg.png') center no-repeat;
    background-position: top left;
  }
  .valueTitle{
    color: #fff;
  }
  .valueLine{
    background-color: #fff;
  }
  .valueText{
    color: #fff;
  }
}
</style>
